

































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Api } from "@/api";
import { defaultOrganization } from "@/constants";
import {
  APICallChart,
  APIKeysList,
  InfoModal,
  NetworkComponent,
  OrganizationMenu,
  QuotaUsage,
} from "@/components";
import { namespace as authNs } from "@/store/auth";
import { Network, Organization, PlanType } from "@/types";

@Component({
  components: {
    APICallChart,
    APIKeysList,
    InfoModal,
    NetworkComponent,
    OrganizationMenu,
    QuotaUsage,
  },
})
export default class DetailsOrganization extends Vue {
  @Getter("plan", { namespace: authNs }) currentPlan!: PlanType;
  @Prop({ type: String, required: true }) id!: string;

  private activateModalVisible = false;

  private apiKeys: string[] = [];

  private organization = defaultOrganization;
  /* eslint-disable no-async-promise-executor */
  private orgPromise: Promise<Organization> = new Promise(
    async (resolve, reject) => {
      try {
        Object.assign(this.organization, await this.getOrganization());
        resolve(this.organization);
      } catch (e) {
        reject(e);
      }
    }
  );

  private async getOrgCallback(): Promise<Organization> {
    return this.orgPromise;
  }

  private async getOrganization(): Promise<Organization> {
    return await Api.getOrganizationData(this.id);
  }

  private async getApiKeys(): Promise<string[]> {
    return await Api.getApiKeys(this.id);
  }

  private async onApiButtonClick(): Promise<void> {
    if (
      this.currentPlan === PlanType.Test &&
      this.organization.network == Network.Mainnet
    ) {
      this.activateModalVisible = true;
    } else {
      this.organization && this.organization.apiEnabled
        ? await this.disableApiButton()
        : await this.enableApiButton();
      this.rerenderApiState();
    }
  }

  private async enableApiButton(): Promise<void> {
    await Api.enableOrganizationApi(this.id);
  }

  private async disableApiButton(): Promise<void> {
    await Api.disableOrganizationApi(this.id);
  }

  private rerenderApiStateKey = 0;

  private rerenderApiState(): void {
    this.resetOrganizationPromise();
    this.rerenderApiStateKey += 1;
  }

  private async resetOrganizationPromise(): Promise<void> {
    this.orgPromise = new Promise(async (resolve, reject) => {
      try {
        Object.assign(this.organization, await this.getOrganization());
        resolve(this.organization);
      } catch (e) {
        reject(e);
      }
    });
  }
}
